<template>
  <div>
    <b-form @submit="postticket">
    <!--begin::Row-->
    <div class="row">
        <div class="col-lg-12">
            <div class="card card-custom card-stretch gutter-b">
                <!--begin::Header-->
                <div class="card-header border-0">
                    <!-- <h3 class="card-title font-weight-bolder text-dark">List Tiket</h3> -->
                    <div class="card-toolbar">
                        
                    </div>
                </div>
                <!--end::Header-->
                <!--begin::Body-->
                <div class="card-body pt-0">
                    <!--begin::Item-->
                    <div>
                        <!--begin::Content-->
                        <div>
                            <div class="form-group row">
                                    <!-- <label class="col-lg-2 col-form-label"></label> -->
                                        <div class="col-lg-12">
                                            <div class="row">
                                                <div class="col-xl-6">
                                                    <!--begin::Input-->
                                                    <div class="form-group">            
                                                        <label>Nama</label>
                                                            <input type="text" disabled v-model="user.name" class="form-control"/>
                                                    </div>
                                     <!--end::Input-->            
                                                </div>
                                                <div class="col-xl-6">
                                                    <!--begin::Input-->
                                                    <div class="form-group">
                                                        <label>Email</label>
                                                         <b-form-input type="text" required v-model="email" class="form-control"></b-form-input>
                                                    </div>
                                        <!--end::Input-->
                                                </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group row">
                                    <!-- <label class="col-lg-2 col-form-label"></label> -->
                                        <div class="col-lg-12">
                                            <div class="row">
                                                <div class="col-xl-6">
                                                    <!--begin::Input-->
                                                    <div class="form-group" v-if="user.id_role === 'ppb_kl'">            
                                                        <label>Instansi</label>
                                                            <input type="text" required v-model="profil.nama_kl" class="form-control"/>
                                                    </div>
                                                    <div class="form-group" v-else-if="user.id_role === 'hipmi_prov' || user.id_role === 'hipmi_kabkot' || user.id_role === 'hipmi_pro' || user.id_role === 'hipmi_kab' || user.id_role === 'hipmi_kot'">            
                                                        <label>Instansi</label>
                                                            <input type="text" v-model="profil.nama_organisasi" class="form-control"/>
                                                    </div>
                                                    <div class="form-group" v-else>            
                                                        <label>Instansi</label>
                                                            <input type="text" v-model="profil.nomenklatur_ptsp" class="form-control"/>
                                                    </div>       
                                                </div>
                                                <div class="col-xl-6">
                                                    <!--begin::Input-->
                                                    <div class="form-group">
                                                        <label>Jenis Pengaduan</label>
                                                         <b-form-select required v-model="selectedpengaduan" :options="jenispengaduan"></b-form-select>
                                                    </div>
                                                </div>
                                        </div>
                                    </div>
                                </div>
                        </div>
                        <!--end::Content-->
                    </div>
                    <!--end::Item-->
                </div>
                <!--end: Card Body-->
            </div>
            <!-- Tambah -->
        </div>
    </div>
    <div class="row">
        <div class="col-lg-12">
            <div class="card card-custom gutter-b">
            <div class="card-header">
                <div class="card-title" style="text-align:center;">
                <h3 class="card-label">Pesan</h3>
                </div>
            </div>
                <!--end::Header-->
                <!--begin::Body-->
                <div class="card-body pt-0">
                    <!--begin::Item-->
                    <div>
                        <!--begin::Content-->
                        <div>
                            <br>
                            <!-- <div class="form-group"> -->
                                    <div class="form-group">            
                                        <label>Subjek</label>
                                        <input type="text" v-model="tentang" class="form-control"/>
                                    </div>
                                    <div class="form-group">
                                        <div>
                                             <vue-editor v-model="pesan" />          
                                        </div>
                                    </div>
                                    <div style="width:300px" class="form-group">
                                        <b-form-file accept="image/*" v-model="lampiran_1"  type="file" ref="file"></b-form-file>
                                    </div>
                                    <div style="width:300px" class="form-group">
                                        <b-form-file accept="image/*" v-model="lampiran_2"  type="file" ref="file"></b-form-file>
                                    </div>
                                    <div style="width:300px" class="form-group">
                                        <b-form-file accept="image/*" v-model="lampiran_3"  type="file" ref="file"></b-form-file>
                                    </div>
                                        
                                <!-- </div> -->
                                <div>
                                    <b-button type="submit" style="background-color: #030A8C; border-color: #030A8C; float:right;" variant="primary"><i class="flaticon-paper-plane-1"></i> Kirim</b-button>
                                </div>
                        </div>
                        <!--end::Content-->
                    </div>
                    <!--end::Item-->
                </div>
                <!--end: Card Body-->
            </div>
            <!-- Tambah -->
        </div>
    </div>
    </b-form>
  </div>
</template>
<style>
  div.messages {
  /* background-color: lightblue; */
  /* width: 110px; */
  height: 500px;
  overflow: auto;
}
</style>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import axios from 'axios';
import { VueEditor } from "vue2-editor";

export default {
  data() {
      return {
        test:'hai',
        user: JSON.parse(localStorage.getItem('user')),
        url: localStorage.getItem('baseapi'),
        token: localStorage.getItem('id_token'),
        ticket:[],
        profil:[],
        search: '',
        pesan:'',
        tentang:'',
        email:'',
        headers: [
            {
            text: 'Nomor Tiket',
            align: 'left',
            sortable: false,
            value: 'id',
            },
            { text: 'Perihal', value: 'tentang' },
            { text: 'Status', value: 'status' },
            { text: 'Jenis Pengaduan', value: 'jenis_pengaduan' },
            
        ],
       selectedpengaduan: null,
       jenispengaduan: [
         { value: null, text: 'Silahkan Pilih' },
         { value: 'Konten/Instrumen Penilaian Kinerja', text: 'Konten/Instrumen Penilaian Kinerja' },
         { value: 'Pengaduan Aplikasi Web', text: 'Pengaduan Aplikasi Web' },
         { value: 'Informasi Lainnya', text: 'Informasi Lainnya' },
       ],
       lampiran_1:'',
       lampiran_2:'',
       lampiran_3:'',
       idtiket:'',
      }
  },
  components: { VueEditor },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Tambah Tiket Baru" }]);
    this.loaddata()
  },
  methods: {
    loaddata(){
            axios.get(this.url+"/tiket", {
              headers: {
                  'xth': this.token
              }
            })
            .then(response => {
                this.ticket = response.data.data;
            }).catch(error=>{
                return error
                });
          if (this.user.id_role === 'ppb_kl') {
            axios.get(this.url+`/profil_ppb_kls?filter=idkl,=,`+this.user.id_ptsp, {
              headers: {
                  'xth': this.token
              }
            })
            .then(response => {
                this.profil = response.data.data;
            }).catch(error=>{
                return error
                });
          } else if(this.user.id_role === 'hipmi_prov' || this.user.id_role === 'hipmi_kabkot' || this.user.id_role === 'hipmi_pro' || this.user.id_role === 'hipmi_kab' || this.user.id_role === 'hipmi_kot'){
            axios.get(this.url+`/profil_asosiasis?filter=idptsp,=,`+this.user.id_ptsp, {
              headers: {
                  'xth': this.token
              }
            })
            .then(response => {
                this.profil = response.data.data;
            }).catch(error=>{
                return error
                });
          } else {
            axios.get(this.url+`/profils?filter=idptsp,=,`+this.user.id_ptsp, {
              headers: {
                  'xth': this.token
              }
            })
            .then(response => {
                this.profil = response.data.data;
            }).catch(error=>{
                return error
                });
          }
            
    },
    postticket(event){
      event.preventDefault()
      if (this.user.id_role === 'ppb_kl') {
        const idpengirim = this.user.id;
        const pengirim = this.user.name;
        const email = this.email;
        const instansi = this.profil.nama_kl;
        const jenis_pengaduan = this.selectedpengaduan;
        const tentang = this.tentang;
      //   const prioritas = '';
        axios
          .post(
            this.url+"/tiket",
            {
              idpengirim,
              pengirim,
              email,
              instansi,
              jenis_pengaduan,
              tentang,
            },
            {
              headers: {
                  "Content-type": "application/json",
                  "xth": this.token,
                  }
              }
            )
          .then((res) => {
              this.$bvToast.toast('Berhasil Dikirim', {
                  title: `Success `,
                  variant: `success`,
                  solid: true
              })
              // this.loaddata()
              console.log(res.data.id);
              console.log(res.data);
              this.idtiket = res.data.id
              this.tambahpercakapan()
            return res;
          })
          .catch((err) => {
            console.log(err)
            this.$bvToast.toast('Gagal Dikirim', {
            title: `Failed `,
            variant: `danger`,
            solid: true
          })
            return err;
          });
      }else if (this.user.id_role === 'hipmi_prov' || this.user.id_role === 'hipmi_kabkot' || this.user.id_role === 'hipmi_pro' || this.user.id_role === 'hipmi_kab' || this.user.id_role === 'hipmi_kot') {
        const idpengirim = this.user.id;
        const pengirim = this.user.name;
        const email = this.email;
        const instansi = this.profil.nama_organisasi;
        const jenis_pengaduan = this.selectedpengaduan;
        const tentang = this.tentang;
      //   const prioritas = '';
        axios
          .post(
            this.url+"/tiket",
            {
              idpengirim,
              pengirim,
              email,
              instansi,
              jenis_pengaduan,
              tentang,
            },
            {
                headers: {
                    "Content-type": "application/json",
                    "xth": this.token,
                  }
              }
            )
          .then((res) => {
              this.$bvToast.toast('Berhasil Dikirim', {
                  title: `Success `,
                  variant: `success`,
                  solid: true
              })
              console.log(res.data.id);
              console.log(res.data);
              this.idtiket = res.data.id
              this.tambahpercakapan()
            return res;
          })
          .catch((err) => {
            console.log(err)
            this.$bvToast.toast('Gagal Dikirim', {
            title: `Failed `,
            variant: `danger`,
            solid: true
          })
            return err;
          });
      }else {
        const idpengirim = this.user.id;
        const pengirim = this.user.name;
        const email = this.email;
        const instansi = this.profil.nomenklatur_ptsp;
        const jenis_pengaduan = this.selectedpengaduan;
        const tentang = this.tentang;
        axios
          .post(
            this.url+"/tiket",
            {
              idpengirim,
              pengirim,
              email,
              instansi,
              jenis_pengaduan,
              tentang,
            },
            {
                  headers: {
                      "Content-type": "application/json",
                      "xth": this.token,
                  }
              }
            )
          .then((res) => {
              this.$bvToast.toast('Berhasil Dikirim', {
                  title: `Success `,
                  variant: `success`,
                  solid: true
              })
              // this.loaddata()
              console.log(res.data.id);
              console.log(res.data);
              this.idtiket = res.data.id
              this.tambahpercakapan()
            return res;
          })
          .catch((err) => {
            console.log(err)
            this.$bvToast.toast('Gagal Dikirim', {
            title: `Failed `,
            variant: `danger`,
            solid: true
          })
            return err;
          });
      }
    },
    
    tambahpercakapan(){
      if (this.user.id_role === 'ppb_kl') {
        let formData = new FormData();
          formData.append('idtiket', this.idtiket);
          formData.append('pengirim', this.user.name);
          formData.append('instansi_pengirim', this.profil.nama_kl);
          formData.append('pesan', this.pesan);
          formData.append('lampiran_1', this.lampiran_1);
          formData.append('lampiran_2', this.lampiran_2);
          formData.append('lampiran_3', this.lampiran_3);
          axios
            .post(this.url+'/helpdesk', formData,{
                  headers: {
                    'Content-Type': 'multipart/form-data',
                    'xth': this.token
                }
              }
            )
            .then(response => {
              this.$bvToast.toast('Berhasil Dikirim', {
                    title: `Success `,
                    variant: `success`,
                    solid: true
                });this.$router.push({ name: "ticket" })
              return response;
            })
            .catch(error=>{
            console.log(error)
            this.$bvToast.toast('Gagal Dikirim', {
            title: `Failed `,
            variant: `danger`,
            solid: true
            })
              return error
              })
      }else if (this.user.id_role === 'hipmi_prov' || this.user.id_role === 'hipmi_kabkot' || this.user.id_role === 'hipmi_pro' || this.user.id_role === 'hipmi_kab' || this.user.id_role === 'hipmi_kot') {
        let formData = new FormData();
                formData.append('idtiket', this.idtiket);
                formData.append('pengirim', this.user.name);
                formData.append('instansi_pengirim', this.profil.nama_organisasi);
                formData.append('pesan', this.pesan);
                formData.append('lampiran_1', this.lampiran_1);
                formData.append('lampiran_2', this.lampiran_2);
                formData.append('lampiran_3', this.lampiran_3);
          axios
            .post(this.url+'/helpdesk', formData,{
                  headers: {
                    'Content-Type': 'multipart/form-data',
                    'xth': this.token
                }
              }
            )
            .then(response => {
              this.$bvToast.toast('Berhasil Dikirim', {
                    title: `Success `,
                    variant: `success`,
                    solid: true
                });this.$router.push({ name: "ticket" })
              return response;
            })
            .catch(error=>{
            console.log(error)
            this.$bvToast.toast('Gagal Dikirim', {
            title: `Failed `,
            variant: `danger`,
            solid: true
            })
              return error
              })
      }else {
        let formData = new FormData();
                formData.append('idtiket', this.idtiket);
                formData.append('pengirim', this.user.name);
                formData.append('instansi_pengirim', this.profil.nomenklatur_ptsp);
                formData.append('pesan', this.pesan);
                formData.append('lampiran_1', this.lampiran_1);
                formData.append('lampiran_2', this.lampiran_2);
                formData.append('lampiran_3', this.lampiran_3);
          axios
            .post(this.url+'/helpdesk', formData,{
                  headers: {
                    'Content-Type': 'multipart/form-data',
                    'xth': this.token
                }
              }
            )
            .then(response => {
              this.$bvToast.toast('Berhasil Dikirim', {
                    title: `Success `,
                    variant: `success`,
                    solid: true
                });this.$router.push({ name: "ticket" })
              return response;
            })
            .catch(error=>{
            console.log(error)
            this.$bvToast.toast('Gagal Dikirim', {
            title: `Failed `,
            variant: `danger`,
            solid: true
            })
              return error
              })
      }
    },
    setActiveTab1(event) {
      this.tabIndex = this.setActiveTab(event);
    },
    setActiveTab2(event) {
      this.tabIndex2 = this.setActiveTab(event);
    },
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      // get all tab links
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set current active tab
      event.target.classList.add("active");

      // set clicked tab index to bootstrap tab
      return parseInt(event.target.getAttribute("data-tab"));
    }
  },
  created(){
    //   this.getuser(this.user)
    // this.getlocaldata()
  }
};
</script>
